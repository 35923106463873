.gFGKXf {
    /*background-image: url(./topwave-74PELLC3.svg);*/
    background-position: 50% 101%;
    background-size: 101%;
    background-repeat: no-repeat;
    background-color: rgb(255, 255, 255);
    color: rgb(56, 69, 97);
    display: flex;
    flex-direction: column;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    height: 100vh;
}


.fiLqzS {
    margin-bottom: 30px;
    font-size: 12px;
    color: rgb(153, 153, 153);
    line-height: 1.5;
}

.bjuzyG {
    margin-bottom: 0px;
    font-weight: 400;
    font-size: 24px;
    color: rgb(56, 69, 97);
}

.eJJsmG {
    width: 100%;
    position: relative;
    text-align: center;
}

.aWMLl {
    max-width: 520px;
    padding: 20px 10px;
    margin: auto;
}


.jiPypZ {
    font-family: Inter, "Google Sans", RobotoDraft, Helvetica, Arial, sans-serif;
    font-weight: 500;
    font-size: 0.9375rem;
    line-height: 1.75;
    padding: 7px 21px;
    border-radius: 4px;
   
    border: 1px solid rgba(89, 40, 229, 0.5);
   
    box-shadow: none;
    white-space: nowrap;
    text-transform: none;
    min-width: 40px;
    width: 50%;
}