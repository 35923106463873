.pgcnt {
    max-width: 900px;
    padding-left: 24px;
    padding-right: 24px;
    width: 100%;
    margin-left: auto;
    box-sizing: border-box;
    margin-right: auto;
    display: block;
    padding: 20px 16px 40px;
}



.pgcnt_n {
    display: flex;
    flex-direction: row;
    -webkit-box-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    align-items: center;
}

.pgcnt_title {
    margin: 0px;
    display: block;
    margin-block: 0.67rem;
    margin-inline: 0px;
    font-size: 2rem;
    font-weight: 600;
    color: rgb(5, 42, 87);
    font-family: Inter, "Google Sans", RobotoDraft, Helvetica, Arial, sans-serif;
    line-height: 1.167;
}