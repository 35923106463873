.jLwJAo {
    display: flex;
    flex-direction: row;
    -webkit-box-align: center;
    align-items: center;
    height: 22px;
    overflow: hidden;
}

.kUvoRW:first-of-type {
    margin-top: 0px;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
}


.kUvoRW {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    flex-direction: column;
    background: rgb(255, 255, 255);
    border: 1px solid rgba(143, 69, 174, 0.1);
    margin-top: -1px;
}

.cRLYMC {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    width: 100%;
    padding-bottom: 4px;
    padding-top: 4px;
}



.cqOETZ {
    width: 60px;
}

.hMcUGD {
    font-size: 20px;
    display: flex;
    flex-direction: column;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    cursor: default;
}

.jUOblL {
    font-size: 10px;
}

/**/

.iBwGJr {
    flex: 1 1 0%;
    width: 100%;
    overflow: hidden;
}

.gbtnLc {
    margin: 0px;
    font-size: 1rem;
    font-family: Inter, "Google Sans", RobotoDraft, Helvetica, Arial, sans-serif;
    font-weight: 400;
    line-height: 1.5;
}

.jLwJAo {
    display: flex;
    flex-direction: row;
    -webkit-box-align: center;
    align-items: center;
    height: 22px;
    overflow: hidden;
}

.jLwJAo > span {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.gzOMsB {
    margin-top: 4px;
    opacity: 0.75;
}

.diIyxM {
    display: flex;
    flex-flow: row wrap;
    width: 100%;
    gap: 5px;
    margin-top: 4px;
    margin-bottom: 4px;
    -webkit-box-pack: start;
    justify-content: flex-start;
    -webkit-box-align: center;
    align-items: center;
}


.ptajN {
    border: none;
    background: rgb(245, 245, 245);
    color: inherit;
}


.hkpzrn {
    max-width: 100%;
    font-family: Inter, "Google Sans", RobotoDraft, Helvetica, Arial, sans-serif;
    font-size: 0.8125rem;
    display: inline-flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    height: 24px;
    border-radius: 16px;
    white-space: nowrap;
    transition: background-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    cursor: default;
    outline: 0px;
    text-decoration: none;
    padding: 0px;
    vertical-align: middle;
    box-sizing: border-box;
    background-color: transparent;
    border: 1px solid rgba(89, 40, 229, 0.7);
    color: rgb(89, 40, 229);
}

/**/


.lboqDb {
    display: flex;
    margin-right: 5px;
    flex-wrap: wrap;
    -webkit-box-pack: end;
    justify-content: flex-end;
    min-width: 150px;
}

.lboqDb {
    display: flex;
    margin-right: 5px;
    flex-wrap: wrap;
    -webkit-box-pack: end;
    justify-content: flex-end;
    min-width: 150px;
}

.IPhhY {
    overflow: hidden;
    text-overflow: ellipsis;
    padding-left: 8px;
    padding-right: 8px;
    white-space: nowrap;
}

.hkpzrn {
    max-width: 100%;
    font-family: Inter, "Google Sans", RobotoDraft, Helvetica, Arial, sans-serif;
    font-size: 0.8125rem;
    display: inline-flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    height: 24px;
    border-radius: 16px;
    white-space: nowrap;
    transition: background-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    cursor: default;
    outline: 0px;
    text-decoration: none;
    padding: 0px;
    vertical-align: middle;
    box-sizing: border-box;
    background-color: transparent;
    border: 1px solid rgba(89, 40, 229, 0.7);
    color: rgb(89, 40, 229);
}

.ptajN {
    border: none;
    background: rgb(245, 245, 245);
    color: inherit;
}


.IPhhY {
    overflow: hidden;
    text-overflow: ellipsis;
    padding-left: 8px;
    padding-right: 8px;
    white-space: nowrap;
}